// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-w-lamb-about-js": () => import("./../src/pages/w-lamb/about.js" /* webpackChunkName: "component---src-pages-w-lamb-about-js" */),
  "component---src-pages-w-lamb-contact-index-js": () => import("./../src/pages/w-lamb/contact/index.js" /* webpackChunkName: "component---src-pages-w-lamb-contact-index-js" */),
  "component---src-pages-w-lamb-contact-thanks-js": () => import("./../src/pages/w-lamb/contact/thanks.js" /* webpackChunkName: "component---src-pages-w-lamb-contact-thanks-js" */),
  "component---src-pages-w-lamb-current-developments-aston-41-js": () => import("./../src/pages/w-lamb/current-developments/aston-41.js" /* webpackChunkName: "component---src-pages-w-lamb-current-developments-aston-41-js" */),
  "component---src-pages-w-lamb-current-developments-church-farm-js": () => import("./../src/pages/w-lamb/current-developments/church-farm.js" /* webpackChunkName: "component---src-pages-w-lamb-current-developments-church-farm-js" */),
  "component---src-pages-w-lamb-current-developments-index-js": () => import("./../src/pages/w-lamb/current-developments/index.js" /* webpackChunkName: "component---src-pages-w-lamb-current-developments-index-js" */),
  "component---src-pages-w-lamb-current-developments-rectory-farm-js": () => import("./../src/pages/w-lamb/current-developments/rectory-farm.js" /* webpackChunkName: "component---src-pages-w-lamb-current-developments-rectory-farm-js" */),
  "component---src-pages-w-lamb-current-developments-the-stables-js": () => import("./../src/pages/w-lamb/current-developments/the-stables.js" /* webpackChunkName: "component---src-pages-w-lamb-current-developments-the-stables-js" */),
  "component---src-pages-w-lamb-index-js": () => import("./../src/pages/w-lamb/index.js" /* webpackChunkName: "component---src-pages-w-lamb-index-js" */),
  "component---src-pages-w-lamb-past-developments-index-js": () => import("./../src/pages/w-lamb/past-developments/index.js" /* webpackChunkName: "component---src-pages-w-lamb-past-developments-index-js" */)
}

